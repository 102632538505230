<div class="invoices-wrapper-responsive">
  <div class="list-wrapper" [ngClass]="!isDataLoaded ? 'show-loader' : ''">
    <div>
      <mat-list >
        <mat-list-item  *ngFor="let invoice of invoices"
          [ngClass]="{ 'pendingInvoice': invoice.isBankTransferPending , 'selectionColor': selection.isSelected(invoice), 'reactivatedMobile' :(!invoice.isBankTransferPending && invoice.reactivated)}"
        ><div class="reactives-link" *ngIf="!invoice.isBankTransferPending && invoice.reactivated " >
          <span  class="reactivated">Reactivated</span>
        </div>
        
          <mat-checkbox checkboxPosition="before"  
              [checked]="isRowSelected(invoice)" 
              (change)="onRowToggled(invoice); totalAmount()"
              [aria-label]="checkboxLabel(invoice)"
              [disabled]="(!selectedCurrency && invoice.isBankTransferPending)"
              (click)="$event.stopPropagation()"
              [ngClass]="{'reactivate': invoice.isBankTransferPending}" 
          >  
          
            <div class="alignment">
              <div class="reactive-block">
                  <div class="pending-text italic" *ngIf="invoice.isBankTransferPending">Pending</div>
                  <button class="blue-btn" *ngIf="invoice.isBankTransferPending" (click)="openReactivateDialog(invoice, $event)">Reactivate?</button>
              </div>
        
              <!-- <div class="pending-text" *ngIf="invoice.isBankTransferPending">Pending</div> -->
              <div class="invoice-details">
                <span class="invoice-ref" *ngIf="invoice.invoiceRef ">Invoice ref: {{ invoice.invoiceRef }}</span>
                <span class="invoice-date">{{ invoice.invoiceDate }}</span>
              </div>
              <div class="invoice-details">
                <div *ngFor="let item of invoice.policyDetails">
                    <span class="policy" *ngIf="invoice.policyDetails?.length == 1">Item: {{ item.policy }}</span>
                </div>

                <span class="total-policies" [hidden]="invoice.isExpanded" *ngIf="invoice.policyDetails!.length > 1">Total Items: {{invoice.policyDetails!.length}}</span> 
                <span [hidden]= "invoice.isExpanded" class="amount">{{selectedCurrencySymbol}}{{invoice.totalAmount | number}}</span>
                <!-- Reactivated Text  -->
                 
              </div>
              <div > 
                
                <div *ngIf="invoice.isExpanded">
                  <div *ngFor="let expand of invoice.policyDetails">
                    <div class="invoice-details">
                      <span *ngIf="invoice.policyDetails!.length > 1" class="policy">Item: {{ expand.policy }}</span>
                      <span class="amount">{{selectedCurrencySymbol}}{{ expand.amount | number }}</span>
                    </div>
                  </div> 
                  <div class="invoice-details">
                    <span class="totalAmount">Total Amount:</span>
                    <span class="totalAmount-value">{{selectedCurrencySymbol}}{{invoice.totalAmount | number}}</span>
                  </div>    
                </div>
                <div class="invoice-details"> 
                  <button class="expandLabel" *ngIf="(invoice.policyDetails!.length > 1)" [hidden]="!selectedCurrency" mat-icon-button aria-label="expand" (click)="toggleExpand(invoice);$event.stopPropagation()"
                   [disabled]="invoice.isBankTransferPending">
                    <mat-label>{{invoice.isExpanded ? 'Collapse': 'Expand'}}</mat-label>
                    <img src="../assets/images/arrow-blue.svg" [ngClass]="invoice.isExpanded ? 'icon-collapse' : '' " />
                  </button>
                </div>
              </div>
            </div>
           
          </mat-checkbox>
          <div class="loader-wrapper">
            <div class="left-column">
              <div class="loader"></div>
              <div class="loader"></div>
            </div>
            <div class="right-column">
              <div class="loader"></div>
              <div class="loader small"></div>
            </div>  
          </div>
        </mat-list-item>
      </mat-list>
    </div>



    <div #paymentOverlay class="payment-panel-wrapper" [hidden]="!(selection.selected.length && isDataLoaded)">
      <div class="radioButtons">
        <div class="text">
           <div> Please check the details are correct and choose your payment type. You will be redirected to our third party payment provider.
          </div>

          <div class="payment-details">
            <div class="details-wrapper">
              <span class="payment-label">Selected:</span>
              <span class="payment-value font-primary-bold">
                {{ selection.selected.length }}
              </span>
            </div>
            <div class="details-wrapper">
              <span class="payment-label">Total:</span>
              <span class="payment-value font-primary-bold">
                {{selectedCurrencySymbol}}{{totalAmt | number}}
              </span>
            </div>
          </div>
          <div class="card-limit" *ngIf=" selectedCurrency === 'Pound Sterling' ">
            <span>
              <img src="../assets/images/card-limit-icon.svg" alt="card-limit-icon" />
            </span>
            <span> Card payment limit: {{selectedCurrencySymbol}}20,000</span>
          </div>
        </div>
        <div class="amount-error" *ngIf= "radioSelectionMobile === 'pay_by_bank' && +totalAmt > 999999999.99">
          Cannot pay invoices exceeding {{selectedCurrencySymbol}}999,999,999. Please select invoices below this amount.
        </div>
        <div class="amount-error" *ngIf= " radioSelectionMobile === 'card' && +totalAmt > 20000.00">
          Cannot pay invoices exceeding {{selectedCurrencySymbol}}20,000. Please select invoices below this amount.
        </div>
        <div class="radio-button-style" *ngIf="isRadioButtonVisible"> 
          <mat-radio-group [(ngModel)]="radioSelectionMobile">
            <mat-radio-button value="card" class="radio-button-card">     
              <span class="radio-label">Pay by Card</span>
            </mat-radio-button>
            <div class="mat-btn-wrapper">
              <mat-radio-button class="radio-button-bank" value="pay_by_bank_transfer"
              [hidden]="selectedCurrency === 'Euro'">
              
              <span class="radio-label">Pay by Bank Transfer</span> 
              
            </mat-radio-button>
            <!-- <a href="javascript:void(0)" class="link-text" (click)="displayPostcodeTooltips = !displayPostcodeTooltips">What is this?</a> -->
              <!-- <div class="tooltip-message-wrapper" *ngIf="displayPostcodeTooltip">
                  This is the postcode of the address your invoice has been issued to.
                  <span class="close-tooltip-wrapper">
                      <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayPostcodeTooltip = false" />
                  </span>
                  <span class="tooltip-arrow-wrapper">
                       <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                  </span>
              </div> -->

              <div class="tooltip-wrapper">
                 <img src="../assets/images/input-tooltip.svg" alt="input-tooltip" class="input-tooltip" (click)="displayPostcodeTooltips = !displayPostcodeTooltips" /> 
                <div class="tooltip-message-wrapper pay-bank-trans" *ngIf="displayPostcodeTooltips">
                  If you are unable to Pay by Card or Pay by Bank, you can pay using traditional bank transfer. Once you
                have selected the payment method, you will be presented with a sort code, account number and payment
                reference. Use these details to transfer the funds from your bank account or via BACS. Failure to use the
                new bank details, reference number and transfer the full amount could result in a delay marking your
                account as paid or result in funds being returned to you.
                    <span class="close-tooltip-wrapper">
                        <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayPostcodeTooltips = false" />
                    </span>
                    <span class="tooltip-arrow-wrapper">
                        <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                    </span>
                </div>
            </div>
          </div>
            <div class="mat-btn-wrapper">
            <mat-radio-button class="radio-button-bank" value="pay_by_bank"
              [hidden]="selectedCurrency === 'Euro'">
              <span class="radio-label">Pay by Bank</span> 
            </mat-radio-button>
            <div class="tooltip-wrapper">
              <img src="../assets/images/input-tooltip.svg" alt="input-tooltip" class="input-tooltip" (click)="displayPostcodeTooltip = !displayPostcodeTooltip" />
              <div class="tooltip-message-wrapper" *ngIf="displayPostcodeTooltip">
                Instead of using a credit or debit card, you can use your own bank account to make a payment. Here's how it
                works:
             
              <div class="steps">
                <ol>
                  <li> Choose "Pay by Bank" as your payment method.</li>
                  <li> Choose your bank </li>
                  <li> You'll be redirected to your online banking platform.</li>
                  <li> Log in to your bank account and confirm the payment details.</li>
                  <li> Authorize the payment.</li>
                  <li> The money will be transferred directly from your bank account.</li>
                </ol>
              </div>
                  <span class="close-tooltip-wrapper">
                      <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayPostcodeTooltip = false" />
                  </span>
                  <span class="tooltip-arrow-wrapper">
                      <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                  </span>
              </div>
          </div>
        </div>
       
          </mat-radio-group>
        </div>          
      </div>
      
      <button mat-flat-button color="primary" class="btn-payment" [disabled]="disablePaymentButton()" (click)="radioButtonValue();makePayment();">
        PROCEED TO PAYMENT
        <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
        <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
      </button>
    </div>
  </div>
</div>