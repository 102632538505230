<div class="dialog-box">
  <div *ngIf="radioSelectionButton === 'pay_by_bank'" mat-dialog-content class="dialog-content">
    <div class="text-wrapper">
      <div class="heading">
        <div class="heading-text">How 'Pay by Bank' works</div>
        <div class="dialog-close">
          <span>
            <img src="../assets/images/dialog-close.svg" alt="close-dialog" (click)="closeDialog()" />
          </span>
        </div>
      </div>
      <div class="error-wrapper" *ngIf="stripeError">
        <div class="title">
          <span class="error-icon">
            <img class="error-icon" src="../assets/images/error-icon.svg" alt="error-icon" />
          </span>
          <span>Sorry! We are unable to connect to the payment gateway at this time. Please try again later.</span>
        </div>
        <div class="description">
          <span class="error-icon">
            <img class="error-bullet" src="../assets/images/error-bullet.svg" alt="error-bullet" />
          </span>
          <span class="description-text">You can also pay your invoices over the phone. Please see your invoice for
            details.</span>
        </div>
      </div>
      <div class="importantSectionWrappery">
        <div class="title">
          <span class="error-icon">
            <img src="../assets/images/important-icon.svg" alt="error-icon" />
          </span>
          <span class="importantTitle">Please note: Pay By Bank is for personal accounts only, not business or corporate
            accounts. Please see alternative payment methods below if you are unable to pay using this method.
          </span>
        </div>
      </div>
      <div class="line"></div>
      <div class="bank-details">
        <div (click)="isExpandedByBank = !isExpandedByBank">
          <mat-icon aria-hidden="false" class="toggle-btn">{{ isExpanded ? 'remove' : 'add'}} </mat-icon>
          <span class="accordion-label" [ngClass]="isExpandedByBank ? 'active' : ''">How 'Pay by bank' Works</span>
        </div>
        <div *ngIf="isExpandedByBank" class="expnadDetails">
          <div class="text">
            Instead of using a credit or debit card, you can use your own bank account to make a payment. Here's how it
            works:
          </div>
          <div class="steps">
            <ol>
              <li> Choose "Pay by Bank" as your payment method.</li>
              <li> Choose your bank </li>
              <li> You'll be redirected to your online banking platform.</li>
              <li> Log in to your bank account and confirm the payment details.</li>
              <li> Authorize the payment.</li>
              <li> The money will be transferred directly from your bank account.</li>
            </ol>
          </div>
        </div>
      </div>

    </div>
    <div class="payment-methods">
      <div class="line"></div>
      <div class="bank-details">
        <div (click)="isExpanded = !isExpanded">
          <mat-icon aria-hidden="false" class="toggle-btn">{{ isExpanded ? 'remove' : 'add'}} </mat-icon>
          <span class="accordion-label" [ngClass]="isExpanded ? 'active' : ''">View available banks</span>
        </div>
        <div *ngIf="isExpanded" class="expnadDetails">
          <div class="bank-text">
            <div>If your bank is listed below please click “Proceed to Pay By Bank” else select a different payment
              method.</div>
            <div class="reminderText">Reminder: This is only available for personal accounts.</div>
          </div>
          <div class="bank-logos" *ngFor="let  logo of logos">
            <img class="images" [src]="logo.path">
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="payByBank">
        <button mat-flat-button color="primary" class="btn-payByBank" value="pay_by_bank"
          [disabled]="+totalAmount > 250000" (click)="onPaymentButtonClick('pay_by_bank');">
          PROCEED TO PAY BY BANK
          <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
          <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
        </button>
        <div class="amount-error" *ngIf="+totalAmount > 250000 ">
          Cannot Pay by Bank for invoices exceeding £250,000. Please select invoices below this amount.
        </div>
        <div class="message" [hidden]="+totalAmount > 250000">
          <div>You will be redirected to our third party payment provider</div>
        </div>
      </div>
      <div class="orText">Or</div>
      <div>
        <div class="noBankavailable">
          <div class="bankTransferTitle">Pay by Card</div>
        </div>
        <div>
          <button mat-flat-button color="secondary" class="btn-payByCard" value="card" [disabled]="+totalAmount > 20000"
            (click)="onPaymentButtonClick('card');">
            PAY BY CARD
            <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
            <img src="../assets/images/arrow-orange.svg" class="btn-icon icon-normal" />
            <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
          </button>
        </div>
      </div>

      <div class="message" [hidden]="+totalAmount > 250000">
        <div>You will be redirected to our third party payment provider</div>
      </div>
      <div class="orText"></div>
      <div>
        <div class="noBankavailable">
          <div class="bankTransferTitle">Pay by bank Transfer</div>
        </div>
        <div>
          <button mat-flat-button color="secondary" class="btn-payByCard" value="card" [disabled]="+totalAmount > 20000"
            (click)="onPaymentButtonClick('customer_balance');">
            PAY BY BANK TRANSFER
            <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
            <img src="../assets/images/arrow-orange.svg" class="btn-icon icon-normal" />
            <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
          </button>
        </div>
      </div>

      <div class="amount-error" *ngIf="+totalAmount > 20000 ">
        Cannot Pay by Card for invoices exceeding £20,000. Please select invoices below this amount.
      </div>
    </div>
  </div>
  <div *ngIf="radioSelectionButton === 'pay_by_bank_transfer'" mat-dialog-content class="dialog-content">
    <div class="text-wrapper">
      <div class="heading">
        <div class="heading-text">Pay by Bank transfer</div>
        <div class="dialog-close">
          <span>
            <img src="../assets/images/dialog-close.svg" alt="close-dialog" (click)="closeDialog()" />
          </span>
        </div>
      </div>
      <div class="error-wrapper" *ngIf="stripeError">
        <div class="title">
          <span class="error-icon">
            <img class="error-icon" src="../assets/images/error-icon.svg" alt="error-icon" />
          </span>
          <span>Sorry! We are unable to connect to the payment gateway at this time. Please try again later.</span>
        </div>
        <div class="description">
          <span class="error-icon">
            <img class="error-bullet" src="../assets/images/error-bullet.svg" alt="error-bullet" />
          </span>
          <span class="description-text">You can also pay your invoices over the phone. Please see your invoice for
            details.</span>
        </div>
      </div>
    </div>
    <div class="payment-methods">
      <div class="line"></div>
      <div class="bank-details">
        <div (click)="isExpanded = !isExpanded">
          <mat-icon aria-hidden="false" class="toggle-btn">{{ isExpanded ? 'remove' : 'add'}} </mat-icon>
          <span class="accordion-label" [ngClass]="isExpanded ? 'active' : ''">What is bank transfer</span>
        </div>
        <div *ngIf="isExpanded" class="expnadDetails">
          <div class="bank-text">
            <div>If you are unable to Pay by Card or Pay by Bank, you can pay using traditional bank transfer. Once you
              have selected the payment method, you will be presented with a sort code, account number and payment
              reference. Use these details to transfer the funds from your bank account or via BACS. Failure to use the
              new bank details, reference number and transfer the full amount could result in a delay marking your
              account as paid or result in funds being returned to you.
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="payByBank">
        <button mat-flat-button color="primary" class="btn-payByBank" value="pay_by_bank"
          [disabled]="+totalAmount > 250000" (click)="onPaymentButtonClick('customer_balance');">
          PAY BY BANK TRANSFER
          <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
          <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
        </button>
        <div class="message">
          You will be redirected to our third party payment provider
        </div>
      </div>
      <div class="orText">Or</div>
      <div>
        <div class="noBankavailable">
          <div class="bankTransferTitle">Pay by Card</div>
        </div>

        <button mat-flat-button color="secondary" class="btn-payByCard" value="card" [disabled]="+totalAmount > 20000"
          (click)="onPaymentButtonClick('card');">
          PAY BY CARD
          <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
          <img src="../assets/images/arrow-orange.svg" class="btn-icon icon-normal" />
          <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
        </button>
        <div class="message">
          You will be redirected to our third party payment provider
        </div>
      </div>
      <div class="orText"></div>
      <div>
        <div class="noBankavailable">
          <div class="bankTransferTitle">Pay by Bank</div>
        </div>

        <button mat-flat-button color="secondary" class="btn-payByCard" value="card" [disabled]="+totalAmount > 20000"
          (click)="onPaymentButtonClick('pay_by_bank');">
          PAY BY BANK
          <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
          <img src="../assets/images/arrow-orange.svg" class="btn-icon icon-normal" />
          <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
        </button>
      </div>
    </div>
  </div>
</div>