<div class="invoices-page-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 offset-lg-1 col-lg-10">
        <div class="title-wrapper">
            <app-page-title [title]="title"></app-page-title>
            <div class="description" *ngIf="!noInvoices && !responseError">
              Your outstanding invoices are listed below. You can select and pay multiple invoices at a time. Please note, if you are owed a refund, this will be paid back to you separately.
            </div>
            <div class="no-invoices-wrapper" *ngIf="noInvoices && !responseError">
              <div class="description">
                You do not have any outstanding invoices to pay.
              </div>
              <div class="description">
                Once an invoice has been issued it will be updated here. Click <span class="signout-btn" (click)="signOut()">here</span> to exit.
              </div>
            </div>
            <div class="error-wrapper" *ngIf="responseError">
              <div class="title">
                <span class="error-icon">
                  <img src="../assets/images/error-icon.svg" alt="error-icon" />
                </span>
                <span>
                  Sorry! We are unable to retrieve your invoices at this time. Please try again later.
                </span>
              </div>
              <div class="description">
                <span class="error-icon">
                  <img src="../assets/images/error-bullet.svg" alt="error-bullet" />
                </span>
                <span class="description-text">You can also pay your invoices over the phone. Please see your policy documentation for details.</span>
              </div>
              <div class="description">
                <span class="error-icon">
                  <img src="../assets/images/error-bullet.svg" alt="error-bullet" />
                </span>
                <span class="description-text"><span class="signout-btn" (click)="signOut()">Click here</span> to exit the application.</span>
              </div>
            </div>
            <div class="error-wrapper" *ngIf="stripeError && !isDialogBoxOpen">
              <div class="title">
                <span class="error-icon">
                  <img src="../assets/images/error-icon.svg" alt="error-icon" />
                </span>
                <span>Sorry! We are unable to connect to the payment gateway at this time. Please try again later.</span>
              </div>
              <div class="description">
                <span class="error-icon">
                  <img class="error-bullet" src="../assets/images/error-bullet.svg" alt="error-bullet" />
                </span>
                <span class="description-text">You can also pay your invoices over the phone. Please see your invoice for details.</span>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="invoices-section" *ngIf="!noInvoices && !responseError">
      <div class="table-actions">
        <div class="actions-title">Select and pay invoices</div>
        <div class="count-wrapper">
          <div class="total-count">Total outstanding invoices: <span class="count font-primary-bold" *ngIf="isDataLoaded">{{totalCount}}</span></div>
          <div class="description" *ngIf="currencies.length > 1">Please select a currency and proceed to payment.</div>
        </div>
        <div class="actions-wrapper" *ngIf="isDataLoaded">
          <div class="dropdown-wrapper currency-wrapper" *ngIf="currencies.length > 1">
            <div class="currency-title-wrapper">
              <div class="dropdown-label">Select Currency</div>
              <div class="tooltip-wrapper">
                <span class="currency-tooltip" (click)="displayCurrencyTooltip = !displayCurrencyTooltip">What is this?</span>
                <div class="tooltip-message-wrapper" *ngIf="displayCurrencyTooltip">
                    Should you have invoices in more than one currency, you can select and pay multiple invoices in a single currency at a time.
                    <span class="close-tooltip-wrapper">
                      <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayCurrencyTooltip = false" />
                    </span>
                    <span class="tooltip-arrow-wrapper">
                      <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                    </span>
                </div>
            </div>
            </div>
            <mat-form-field class="currency-container" appearance="outline">
              <mat-select placeholder="Please select a currency" disableOptionCentering  panelClass="dropdownPanel" [(ngModel)]=
              "selectedCurrency" (selectionChange)="onCurrencySelection()" [ngClass]= "{'icon-collapse' : select?.panelOpen }" #select>
                <mat-option *ngFor="let option of currencies" [value]="option.label">
                  {{option.label}} ({{option.symbol}})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="dropdown-wrapper sort-wrapper">
            <div class="dropdown-label">Sort</div>
            <mat-form-field class="sort-container" appearance="outline">
              <mat-select placeholder="Please choose sort" disableOptionCentering  panelClass="dropdownPanel"  [(ngModel)]=
              "selectedOption" (selectionChange)="onOptionSelection()"  [ngClass]= "{'icon-collapse' : select?.panelOpen }" #select >
                <mat-option *ngFor="let option of options" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      
      <div class="bankTransferPending" *ngIf="isPendingInvoice()">
        <img src="../assets/images/warning-icon.svg" alt="error-icon" class="error-icon" />
        <span class="error-text">If invoices are not available to select, it is because you have previously chosen to pay for them via Bank Transfer and we have yet to receive the transferred funds.<br />You can re-enable an invoice for payment selecting 'Reactivate' on the invoice</span>
      </div>

      <div class="row" *ngIf="!showListView && dataSource.data.length">
        <div class="col-lg-8">
          <div class="table-wrapper" [ngClass]="isSafariBrowser ? 'safariBrowser' : '' ">
            <!--table-->
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows [ngClass]="!isDataLoaded ? 'hide-expand' : ''">
            
              <!-- select -->
              <ng-container matColumnDef="select" >
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event?toggleAllRows():null; totalAmount()" 
                    [checked]="selection.hasValue && isAllSelected()"
                    [disabled]="!selectedCurrency || !nonGreyedInvoice().length"
                  >
                  </mat-checkbox>
                  <mat-label>Select</mat-label>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    *ngIf="!(row.isBankTransferPending)" 
                    (click)="$event.stopPropagation()"
                    (change)="onRowToggled(row); totalAmount()"
                    [checked]="isRowSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                    [disabled]="!selectedCurrency"
                  ></mat-checkbox>
                  
                  <span class="pending-text italic" *ngIf="row.isBankTransferPending">Pending</span>
                  <!-- Reactivate Button -->
                   <button class="blue-btn" *ngIf="row.isBankTransferPending" (click)="openReactivateDialog(row)">Reactivate?</button>
                   <span *ngIf="!row.isBankTransferPending && row.reactivated " class="reactivated">Reactivated</span>
                  <!-- <button mat-stroked-button color="primary" class="reactivate-button" (click)="openReactivateDialog(row)">
                    Reactivate
                  </button> -->
                  </td>
                  </ng-container>

              <!-- Invoice date-->
              <ng-container matColumnDef="invoiceDate">
                <th mat-header-cell *matHeaderCellDef>Invoice date</th>
                <td mat-cell *matCellDef="let element">{{ element.invoiceDate }}</td>
              </ng-container>

              <!--  Invoice ref-->
              <ng-container matColumnDef="invoiceRef">
                <th mat-header-cell *matHeaderCellDef>Invoice ref</th>
                <td mat-cell *matCellDef="let element">{{element.invoiceRef}}</td>
              </ng-container>

              <!-- policy-->
              <ng-container matColumnDef="policyDetails">
                <th mat-header-cell *matHeaderCellDef>Item(s)</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.policyDetails.length > 1; else elseBlock">
                    Total items: {{element.policyDetails.length}}
                  </span>
                  <ng-template #elseBlock>{{element.policyDetails[0]?.policy}}</ng-template>
                </td>
              </ng-container>

              <!-- amount-->
              <ng-container matColumnDef="totalAmount">
                <th mat-header-cell *matHeaderCellDef>Amount <span *ngIf="selectedCurrency">({{selectedCurrencySymbol}})</span></th>
                <td mat-cell *matCellDef="let element ">{{selectedCurrencySymbol}}{{element.totalAmount | number}}</td>
              </ng-container>

              <!-- Expand/Collapse button column -->
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button aria-label="expand row" (click)="toggleRow(element); $event.stopPropagation()" *ngIf="element.policyDetails.length > 1" [disabled]="element.isBankTransferPending">
                    <span class="action-button">
                      <img src="../assets/images/arrow-blue.svg" [ngClass]="expandCollapseLable(element) ? 'icon-collapse' : '' " />
                    <span class="button-label">{{expandCollapseLable(element) ? 'Collapse' : 'Expand' }}
                    </span>
                    </span>
                  </button>
                </td>
              </ng-container>
            
              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element;" [attr.colspan]="columnsToDisplayWithExpand.length">
                  <div class="example-element-detail"
                    [@detailExpand]="toggleExpandPanel(element)">
                    <table class="child-table" width="100%">
                      <tbody>
                        <tr class="policy-details" *ngFor="let policyDetail of element.policyDetails">
                          <td class="select"></td>
                          <td class="date"></td>
                          <td class="ref"></td>
                          <td class="policy">{{policyDetail.policy}}</td>
                          <td class="amount">{{selectedCurrencySymbol}}{{policyDetail.amount | number}}</td>
                          <td class="expand"></td>
                        </tr>
                        <tr class="total-row">
                          <td class="select"></td>
                          <td class="date"></td>
                          <td class="ref"></td>
                          <td class="policy">Total Amount:</td>
                          <td class="amount">{{selectedCurrencySymbol}}{{element.totalAmount | number}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </ng-container>

              <tr   mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                [ngClass]="{ 'pendingInvoice' : element.isBankTransferPending , 'reactivated-row': (!element.isBankTransferPending && element.reactivated) }"
              ></tr>
              <tr mat-row  [ngClass]="{'reactivated-row': (!row.isBankTransferPending && row.reactivated) }" *matRowDef="let row; columns: ['expandedDetail']; let i = dataIndex" class="example-detail-row" [class.detail-expanded-row]="expandedElement === row && isDataLoaded" [class]="addClassBasedOnIndex(i)"></tr>
            </table>
          </div>
        </div>
        <div class="col-lg-4">
          <mat-card class="card payment-card">
            <mat-card-header>
              <mat-card-title class="card-title">Selected invoices</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="payment-details">
                <div class="details-wrapper">
                  <span class="payment-label">Invoices selected:</span>
                  <span *ngIf="isDataLoaded" class="payment-value" [ngClass]="selection.selected.length ? 'font-primary-bold' : ''">
                    {{ selection.selected.length ? selection.selected.length : '-' }}
                  </span>
                </div>
                <div class="details-wrapper">
                  <span class="payment-label">Total amount:</span>
                  <span *ngIf="isDataLoaded" class="payment-value" [ngClass]="+totalAmt ? 'font-primary-bold' : ''">
                    {{ +totalAmt ? (selectedCurrencySymbol + (totalAmt | number)) : '-' }}
                  </span>
                </div>
              </div>
            </mat-card-content> 
            <mat-card-actions>
              <div>
              <div class="amount-error" *ngIf= " radioSelection === 'pay_by_bank' && +totalAmt > 999999999.99">
                Cannot pay invoices exceeding {{selectedCurrencySymbol}}999,999,999. Please select invoices below this amount.
              </div>
              <div class="amount-error" *ngIf= " radioSelection === 'card' && +totalAmt > 20000.00">
                Cannot pay invoices exceeding {{selectedCurrencySymbol}}20,000. Please select invoices below this amount.
              </div>
              <div *ngIf="isRadioButtonVisible" class="radioButtons">
                  <div class="payment-type" [class.selected] = " radioSelection === 'card'|| radioSelection === 'pay_by_bank' || radioSelection === 'customer_balance' " [ngClass]="{'backgroundChange': radioSelection }">
                      <div class="text" [hidden]="radioSelection" >
                        Please check the details are correct and choose your payment type
                     </div>
                      <div class="radio-button-style"> 
                        <mat-radio-group  [(ngModel)]="radioSelection">
                      <div class="button-card">
                      <mat-radio-button value = "card" class= "radio-button-card">     
                        <span class = "radio-label">Pay by Card ({{selectedCurrencySymbol}}20,000 limit)</span>
                      </mat-radio-button>
                      </div>  
                      <div class="button-bank" [hidden]=" selectedCurrency === 'Euro' ">
                        <mat-radio-button class="radio-button-bank inline-flex-gap" value="pay_by_bank_transfer">
                          <span class="radio-label">Pay by Bank Transfer</span>
                          <div class="tooltip-wrapper">
                            <!-- <img src="../assets/images/input-tooltip.svg" alt="input-tooltip" class="input-tooltip"
                              (click)="displayPostcodeTooltip = !displayPostcodeTooltip" /> -->
                              <a href="javascript:void(0);" class="link-text" (click)="displayPostcodeTooltip = !displayPostcodeTooltip">What is this?</a>
                            <div class="tooltip-message-wrapper" *ngIf="displayPostcodeTooltip">
                              <p>If you are unable to pay by Card or Pay By Bank, you can pay using traditional a bank transfer.
                                Once you have selected this payment method, you will be presented with a sort code, account number and payment reference.
                                Use these details to transfer the funds from your bank account or via BACs.
                                Please do not transfer funds to bank details provides on pervious,
                                this will take linger to mark your account as paid or could means funds being returned to you.</p>
                      
                              <span class="close-tooltip-wrapper">
                                <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip"
                                  (click)="displayPostcodeTooltip = false" />
                              </span>
                              <span class="tooltip-arrow-wrapper">
                                <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                              </span>
                            </div>
                          </div>
                        </mat-radio-button>

                      </div>
                      <div class="button-bank" [hidden] = " selectedCurrency === 'Euro' ">  
                        <mat-radio-button class = "radio-button-bank" value = "pay_by_bank"
                        >
                          <span class = "radio-label">Pay by Bank</span> 
                        </mat-radio-button>
                      </div>
                     <!--  <div class="euro-wrapper" *ngIf = " selectedCurrency === 'Euro' "> 
                        <div class="button-wrapper">
                        <mat-radio-button class = "radio-button-bank-transfer" value = "customer_balance"
                        >
                        <span class = "radio-label">Pay by bank transfer</span>
                        </mat-radio-button>
                    </div> 
                         <div class="radio-tooltip-wrapper"> 
                          <div class="tooltip-wrapper">
                            <span class="BankTransfer-tooltip" (click)="displayBankTransferTooltip = !displayBankTransferTooltip ">What is this?</span>
  
                            <div class="tooltip-message-wrapper" *ngIf="displayBankTransferTooltip">
                              If you are unable to pay by Card or Pay By Bank, you can pay using traditional a bank transfer.  Once you have selected this payment method, you will be presented with a sort code, account number and payment reference.  Use these details to transfer the funds from your bank account or via BACs.  Please do not transfer funds to bank details provided on previous invoices, this will take longer to mark your account as paid or could mean funds being returned to you.
                                <span class="close-tooltip-wrapper">
                                  <img src="../assets/images/close.svg" alt="close-tooltip" class="close-tooltip" (click)="displayBankTransferTooltip = false" />
                                </span>
                                <span class="tooltip-arrow-wrapper">
                                  <img src="../assets/images/tooltip.svg" alt="tooltip-arrow" class="tooltip-arrow" />
                                </span>
                            </div> 
                       </div>
                        </div> 
                      </div> -->
                    </mat-radio-group>
                  </div>
                </div> 
              </div>
              <button mat-flat-button color="primary" [disabled]="disablePaymentButton()" class="btn-payment" (click)="storeInvoicesState()">
                PROCEED TO PAYMENT
                <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
                <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
              </button>
               <div class="message" [hidden]= "disablePaymentButton()">
                You will be redirected to our third party payment provider
              </div> 
            </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

      <div class="listview-wrapper" *ngIf="showListView">
        <app-invoice-mobile [invoices]="dataSource.data" (invoicesPayment)="invoicesListPaymentHandler($event);"  (invoicestotalAmount)="invoiceMobileAmtHandler($event)" (radioButtonSelectionMobile)="radioButtonSelectionHandler($event);" [selectedCurrency]="selectedCurrency" [selectedCurrencySymbol]="selectedCurrencySymbol" [isDataLoaded]="isDataLoaded"></app-invoice-mobile>
      </div>
    </div>   
  </div>
</div>