import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  private invoicesAPIErrorFlag = new Subject<boolean>();
  private footerBottomPadding = new Subject<number>();

  apiURL: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  /**
   * To fetch the list of invoices based on the clientRef
   * @returns response or error received by the API call
   */
  getInvoices(body:any) {
    //let url = "https://localhost:5001/Invoices";
    /* let clientRef = sessionStorage.getItem("clientRef");
    let queryParams = new HttpParams();
    queryParams = queryParams.append("clientRef", "" + clientRef); */
    
    /*
    POC environment
    const url =
      'https://ggbuk-apim-retailpaymentportal-dev.azure-api.net/ggbuk-retailpaymentportal-func-dev-6rbocekaik5iy/InvoiceFunction';
    'Ocp-Apim-Subscription-Key': '4d00d010d3e841be88590196bceb4798'
    */

    // localhost
    //const res = this.http.get('/api/invoices', { params: queryParams });

    const url = this.apiURL + '/api/invoices';
    return this.http.post(url, body);
  }
    /**
   * To fetch the list of invoices based on the clientRef
   * @returns response or error received by the API call
   */
    reactivateInvoice(body:any) {
      //let url = "https://localhost:5001/reactivate";
      /* let clientRef = sessionStorage.getItem("clientRef");
      let queryParams = new HttpParams();
      queryParams = queryParams.append("clientRef", "" + clientRef); */
      
      /*
      POC environment
      const url =
        'https://ggbuk-apim-retailpaymentportal-dev.azure-api.net/ggbuk-retailpaymentportal-func-dev-6rbocekaik5iy/ReactivateInvoiceFunction';
      'Ocp-Apim-Subscription-Key': '4d00d010d3e841be88590196bceb4798'
      */
  
      // localhost
      //const res = this.http.get('/api/reactivate', { params: queryParams });
  
      const url = this.apiURL + '/api/reactivate';
      return this.http.post(url, body);
    }

  /**
   * Sets true/false based on the status of the Payment API call
   * made by invoices component
   * @param flag: boolean 
   */
  setInvoicesAPIErrorFlag(flag: boolean) {
    this.invoicesAPIErrorFlag.next(flag);
  }

  /**
   * Return true/false based on the status of the Payment API call
   * made by invoices component
   * @returns observable of boolean
   */
  getInvoicesAPIErrorFlag(): Observable<boolean> {
    return this.invoicesAPIErrorFlag.asObservable();
  }

  /**
   * Sets the padding of the invoices payment panel (mobile screen)
   */
  setFooterBottomPadding(padding: number) {
    this.footerBottomPadding.next(padding);
  }

  /**
   * Returns the padding of the invoices payment panel (mobile screen)
   * @returns Observable of number
   */
  getFooterBottomPadding(): Observable<number> {
    return this.footerBottomPadding.asObservable();
  }
}
