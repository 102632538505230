import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';

import { NgxCaptchaModule } from 'ngx-captcha';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './components/login/login.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { RppHelpComponent } from './components/rpp-help/rpp-help.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { OurBrandsComponent } from './components/our-brands/our-brands.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { InvoiceMobileComponent } from './components/invoice-mobile/invoice-mobile.component';
import { SuccessComponent } from './components/success/success.component';
import { PayByBankDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { InvoiceReactivateDialogComponent } from './components/invoice-reactivate-dialog/invoice-reactivate-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    PageTitleComponent,
    LoginComponent,
    InvoicesComponent,
    RppHelpComponent,
    FooterComponent,
    HeaderComponent,
    MainComponent,
    OurBrandsComponent,
    InvoiceMobileComponent,
    SuccessComponent,
    PayByBankDialogComponent,
    InvoiceReactivateDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    NgxCaptchaModule,
    RecaptchaModule,       
    RecaptchaFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
