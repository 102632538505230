<div class="dialog-box">
    <div mat-dialog-content class="dialog-content">
        <div class="dialog-footer">
            <button style="width: 1px; height: 1px; opacity: 0;">.</button> 
                    </div>
        <!-- Step 1 | confirmation Block-->
         <div  *ngIf="!isLoader && !reactivatedSuccess && !reactivateError" class="text-wrapper"  >
            <div class="heading">
                <div class="heading-text">Reactivate invoice for payment?</div>
                <div class="dialog-close">
                    <span>
                        <img src="../assets/images/dialog-close.svg" alt="close-dialog" (click)="closeDialog()" />
                    </span>
                </div>
            </div>
            <div class="text">
                This invoice is currently marked as pending because you previously chose to
                pay by Bank Transfer. <br />If you undo the pending status, the invoice will become
                available to select for payment again, allowing you to choose a new payment
                method.
            </div>
            <div class="importantSectionWrappery">
                <div class="title">
                    <span class="error-icon">
                        <img src="../assets/images/reactivate-wr-icon.svg" alt="error-icon" />
                    </span>
                    <span class="importantTitle"> Please ensure that you have not already paid this invoice before
                        updating
                        its status and paying again. If an invoice is paid twice, you will be
                        issued a refund for the duplicate payment.
                    </span>
                </div>
            </div>
 
            <div class="dialog-footer">
                
                <button mat-flat-button color="secondary" class="btn-payByCard text-uppercase" value="card" (click)="reactivatePendingInvoice()">
                   Yes, Reactivate </button>
                <button mat-flat-button color="primary" class="btn-payByCard text-uppercase" value="card" (click)="closeDialog()">
                    Cancel </button>
            </div>

        </div>  


        <!-- Step 2 | Error Block -->
        <div  *ngIf="!isLoader && reactivateError" class="text-wrapper"  >
            <div class="heading">
                <div class="heading-text">Reactivate Unsuccessful</div>
                <div class="dialog-close">
                    <span>
                        <img src="../assets/images/dialog-close.svg" alt="close-dialog" (click)="closeDialog()" />
                    </span>
                </div>
            </div>
           
            <div class="error-wrapper" >
          <div class="title">
            <span class="error-icon">
              <img class="error-icon" src="../assets/images/error-icon.svg" alt="error-icon" />
            </span>
            <span>Sorry! We are unable to reactivate your invoice at this time. Please try again later.</span>
          </div>
          <div class="description">
          
            <span class="description-text">If you need additional support, please contact your Gallagher representative using the contact details on your Gallagher documenation.</span>
          </div>
        </div>  
            <div class="dialog-footer">
                <button mat-flat-button color="secondary" class="btn-payByCard text-uppercase" value="card" (click)="closeDialog()">
                    Close </button>
            </div>
        </div>  

          <!-- Step 3 | Success Block -->
          <div *ngIf="!isLoader && reactivatedSuccess" class="text-wrapper" >
            <div class="heading">
                <div class="heading-text">Success</div>
                <div class="dialog-close">
                    <span>
                        <img src="../assets/images/dialog-close.svg" alt="close-dialog" (click)="closeDialog()" />
                    </span>
                </div>
            </div>
           
            <div class="success-wrapper" >
                <div class="title">
                    <span class="error-icon">
                    <img class="error-icon" src="../assets/images/success-icon.svg" alt="success-icon" />
                    </span>
                    <span>Reactivated</span>
                    <!-- <span>Your invoice has now been re-enabled.</span> -->
                </div>
                <div class="description">
                    <span class="description-text">Your invoice has now been re-enabled. You can now select it and choose a payment method.</span>
                </div>
            </div>  
            <div class="dialog-footer">
                <button mat-flat-button color="secondary" class="btn-payByCard text-uppercase" value="card" (click)="closeDialog()">
                    Close </button>
            </div>
        </div>  

        
        
          <!-- Step 4 | Loader Block-->
          <div  *ngIf="isLoader && (!reactivatedSuccess || !reactivateError) " class="text-wrapper" >
            <div class="heading">
                <div class="heading-text">Reactivate invoice for payment?</div>
                <div class="dialog-close">
                    <span>
                        <img src="../assets/images/dialog-close.svg" alt="close-dialog" (click)="closeDialog()" />
                    </span>
                </div>
            </div>
           
           <div class="loader-wrapper">
                <div class="loader-background" >
                    <div class="loader"></div>
                    <div class="loader-text">Please wait while we reactivate your invoice</div>
                </div>  
            </div> 
        </div>  
        
    </div>
</div>