import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InvoicesService } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-invoice-reactivate-dialog',
  templateUrl: './invoice-reactivate-dialog.component.html',
  styleUrls: ['./invoice-reactivate-dialog.component.scss']
})
export class InvoiceReactivateDialogComponent implements OnInit {

  isLoader: boolean = false; // Loader visibility
  reactivateError: boolean = false; // Error block visibility
  reactivatedSuccess: boolean = false; // Success block visibility
  constructor(public dialogRef: MatDialogRef<InvoiceReactivateDialogComponent>,     private invoiceService: InvoicesService,
  ) { }



  ngOnInit(): void {
  }
  closeDialog(): void {

    this.dialogRef.close(true);
    this.reactivateError = false; // Reset error
    this.reactivatedSuccess = false; // Reset success
    this.isLoader = false;
  }
  reactivatePendingInvoice(){
    this.isLoader = true; // Show loader
    this.reactivateError = false; // Reset error
    this.reactivatedSuccess = false; // Reset success
    
    if (sessionStorage.getItem("clientRef")) { // Check to see if user is logged in
      let clientRef = sessionStorage.getItem('clientRef');
      let postCode = sessionStorage.getItem('postCode');
      let bladeCode = sessionStorage.getItem('bladeCode');
      let invoiceRef = sessionStorage.getItem('invoiceRef');
      let payload = { clientRef, postCode, bladeCode, invoiceRef };

    this.invoiceService.reactivateInvoice(payload).subscribe((data: any) => { // API call to fetch invoices
      if (data) { // Check for empty data
        this.isLoader = false;
        sessionStorage.setItem("reactivated", 'true');
        this.reactivatedSuccess = true;
      } else {
        this.isLoader = false;
        this.reactivatedSuccess = false;
        this.reactivateError = true; // Show error block
      }
    }, _err => {
      this.isLoader = false;
      this.reactivatedSuccess = false;
      this.reactivateError = true; // Show error block
    });
        // // Simulating API call with a timeout
        // setTimeout(() => {
        //   // Simulate random success or error
        //   const isSuccess = Math.random() > 0.5; // 50% chance of success
    
        //   this.isLoader = false; // Hide loader
    
        //   if (isSuccess) {
        //     sessionStorage.setItem("reactivated", 'true');
        //     this.reactivatedSuccess = true; // Show success block
        //   } else {
        //     this.reactivateError = true; // Show error block
        //   }
        // }, 2000); // Simulate 2-second API response time
    
  }
  } 


}
